import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';

const ContactPage = props => (
  <Layout>
    <Helmet>
      <title>JIT Machine Corp</title>
      <meta name="description" content="Contact page" />
    </Helmet>

    <section id="banner" className="style2">
      <div className="inner">
        <header className="major">
          <h1>Contact Us</h1>
        </header>
        <div className="content">
          <p>
            JIT MACHINE CORPORATION WILL HAVE A COMPETITIVELY PRICED QUOTE BACK
            TO YOU WITHIN 24 HOURS.
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default ContactPage;
